<!--  -->
<template>
  <div class="">
    <!-- 面包屑组件-->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>用户管理</el-breadcrumb-item>
      <el-breadcrumb-item>用户列表</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card class="box-card">
      <el-row :gutter="24">
        <el-col :span="5">
          <role
            ref="roleChild"
            @role-child="initQueryInfo"
            @position-child="initQueryInfo"
          ></role>
        </el-col>
        <el-col :span="3">
          <dealer
            ref="dealerChild"
            @dealer-child="initQueryInfoForDealer"
          ></dealer>
        </el-col>
        <el-col :span="2">
          <el-select
            v-model="queryInfo.query.state"
            clearable
            placeholder="请选择状态"
          >
            <el-option
              v-for="item in stateList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="4">
          <el-input
            placeholder="请输入查询关键字"
            width="20px"
            v-model="queryInfo.query.userName"
            type="text"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="searchList"
            ></el-button>
          </el-input>
        </el-col>
        <el-col :span="6">
          <el-button type="info" @click="clearCondition">清空条件</el-button>
          <el-button type="primary" @click="addDialogVisible = true"
            >添加用户</el-button
          >
        </el-col>
        <el-col :span="4">
          <span class="uploadtime">最近同步时间：{{ uploadtime }}</span>
        </el-col>
      </el-row>

      <el-table :data="userList" stripe border v-loading="loading">
        <el-table-column label="#" type="index"></el-table-column>
        <el-table-column
          label="用户名"
          prop="userName"
          width="150px"
        ></el-table-column>
        <el-table-column label="Newbie账号" prop="nbAccount"></el-table-column>
        <el-table-column label="姓名" prop="nameCn"></el-table-column>
        <el-table-column label="邮箱" prop="email"></el-table-column>
        <!-- <el-table-column label="审核状态" prop="state"></el-table-column> -->
        <el-table-column label="经销商code" prop="dealerCode"></el-table-column>
        <el-table-column label="角色" prop="userRolePositionVos">
          <template slot-scope="scope">
            <div
              v-for="item in (scope.row.userRolePositionVos || []).map(
                (v) => v.roleName
              )"
              :key="item.id"
            >
              {{ item }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="职位" prop="userRolePositionVos">
          <template slot-scope="scope">
            <div
              v-for="item in (scope.row.userRolePositionVos || []).map(
                (v) => v.positionName
              )"
              :key="item.id"
            >
              {{ item }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="创建时间"
          prop="createTime"
          width="180px"
        ></el-table-column>
        <el-table-column label="状态" width="120px">
          <template slot-scope="scope">
            <el-switch
              @change="updateStatus(scope.row)"
              :disabled="scope.row.isDelete"
              :value="scope.row.state === 'PASS'"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="260px">
          <template slot-scope="scope">
            <el-button
              type="primary"
              icon="el-icon-edit"
              size="mini"
              @click="showEditDialog(scope.row.id)"
            ></el-button>
            <el-tooltip effect="light" content="重置密码" placement="top">
              <el-button
                type="warning"
                icon="el-icon-refresh-right"
                size="mini"
                @click="resetPassword(scope.row.id)"
              ></el-button>
            </el-tooltip>
            <el-tooltip effect="light" content="重置设备号" placement="top">
              <el-button
                type="info"
                icon="el-icon-refresh-right"
                size="mini"
                @click="resetDeviceId(scope.row.id)"
              ></el-button>
            </el-tooltip>
            <el-popconfirm
              style="margin-left: 10px"
              title="确定永久删除吗"
              icon="el-icon-info"
              icon-color="red"
              @confirm="deleteById(scope.row.id)"
            >
              <el-button
                slot="reference"
                type="danger"
                icon="el-icon-delete"
                size="mini"
              ></el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pageNum"
        :page-sizes="[5, 10, 30, 50]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>

    <!-- 添加用户-->
    <el-dialog
      :close-on-click-modal="false"
      title="添加用户"
      :visible.sync="addDialogVisible"
      width="50%"
      @close="closeAddDialog"
    >
      <el-form
        ref="addFormRef"
        :model="addForm"
        :rules="addFromRules"
        label-width="80px"
      >
        <el-form-item label="用户名" prop="userName">
          <el-input v-model="addForm.userName"></el-input>
        </el-form-item>
        <el-form-item label="姓名" prop="nameCn">
          <el-input v-model="addForm.nameCn"></el-input>
        </el-form-item>
        <el-form-item label="邮箱" prop="email">
          <el-input v-model="addForm.email" type="text"></el-input>
        </el-form-item>
        <el-form-item label="经销商" prop="dealerCode">
          <dealer ref="dealerAddRef" @dealer-child="initAddUserInfo"></dealer>
        </el-form-item>
        <el-form-item label="职位" prop="role">
          <role
            ref="roleAddRef"
            @role-child="initAddUserRole"
            @position-child="initAddUserPosition"
          ></role>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addUser">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 修改用户 -->
    <el-dialog
      :close-on-click-modal="false"
      title="修改用户"
      :visible.sync="editDialogVisible"
      width="50%"
      @close="closeEditDialog"
    >
      <el-form
        ref="editFormRef"
        :model="editForm"
        :rules="editFormRules"
        label-width="80px"
      >
        <el-form-item label="用户名" prop="userName">
          <el-input v-model="editForm.userName"></el-input>
        </el-form-item>
        <el-form-item label="姓名" prop="nameCn">
          <el-input v-model="editForm.nameCn"></el-input>
        </el-form-item>
        <el-form-item label="邮箱" prop="email">
          <el-input v-model="editForm.email"></el-input>
        </el-form-item>
        <el-form-item label="经销商" prop="dealerCode">
          <dealer
            ref="dealerEditRef"
            prop="dealer"
            :dealerCode="editForm.dealerCode"
            @dealer-child="initEditUserInfo"
          ></dealer>
        </el-form-item>
        <el-form-item label="职位" prop="role">
          <role
            ref="roleEditRef"
            :roleId="editForm.roleId"
            :positionId="editForm.positionId"
            @role-child="initEditUserRole"
            @position-child="initEditUserPosition"
          ></role>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="updateUser">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import Role from "../common/Role.vue";
import Dealer from "../common/Dealer.vue";

export default {
  // import引入的组件需要注入到对象中才能使用
  components: { Role, Dealer },
  data() {
    var checkRole = (rule, value, cb) => {
      if (this.addForm.roleId) {
        return cb();
      }
      cb(new Error("请选择角色"));
    };
    var checkEditRole = (rule, value, cb) => {
      if (this.editForm.roleId) {
        return cb();
      }
      cb(new Error("请选择角色"));
    };
    // var checkEmail = (rule, value, cb) => {
    //   var reg = /^[A-Za-zd0-9]+([-_.][A-Za-zd]+)*@([A-Za-zd]+[-.])+[A-Za-zd]{2,5}$/
    //   if (reg.test(value)) {
    //     return cb()
    //   }
    //   cb(new Error('请输入合法的邮箱'))
    // }
    // 这里存放数据
    return {
      uploadtime: "",
      loading: true,
      addFromRules: {
        userName: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          { min: 3, message: "至少3位", trigger: "blur" },
        ],
        nameCn: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        email: [
          { required: true, message: "请输入邮箱", trigger: "blur" },
          // { validator: checkEmail, trigger: 'blur' }
        ],
        role: [{ validator: checkRole, trigger: "blur" }],
      },
      editFormRules: {
        userName: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          { min: 3, message: "至少3位", trigger: "blur" },
        ],
        nameCn: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        email: [
          { required: true, message: "请输入邮箱", trigger: "blur" },
          // { validator: checkEmail, trigger: 'blur' }
        ],
        role: [{ validator: checkEditRole, trigger: "blur" }],
      },
      addForm: {
        userName: "",
        nameCn: "",
        email: "",
        roleId: null,
        positionId: null,
      },
      editForm: {
        id: "",
        userName: "",
        nameCn: "",
        email: "",
        roleId: null,
        positionId: null,
      },
      queryInfo: {
        query: {
          roleId: null,
          positionId: null,
          userName: null,
          dealerCode: null,
          state: null,
        },
        pageNum: 1,
        pageSize: 5,
      },
      addDialogVisible: false,
      editDialogVisible: false,
      total: 0,
      userList: [],
      stateList: [
        {
          label: "通过",
          value: "PASS",
        },
        {
          label: "虚拟",
          value: "VICTUAL",
        },
        {
          label: "待审核",
          value: "EXAMINE",
        },
        {
          label: "拒绝",
          value: "REFUSE",
        },
      ],
    };
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    getuploadtime() {
      this.$http
        .get("/ma/base/getSyncTime", {
          params: { code: "user" },
        })
        .then((res) => {
          if (res.data.code === 0) {
            this.uploadtime = res.data.data;
          }
        });
    },
    closeEditDialog() {
      this.$refs.editFormRef.resetFields();
    },
    async updateUser() {
      this.$refs.editFormRef.validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.put(
            "/ma/user/update",
            this.editForm
          );
          if (res.code === 0) {
            this.$message.success(res.msg);
            this.getUserList();
            this.editDialogVisible = false;
          } else {
            this.$message.error(res.msg);
          }
        }
      });
    },
    async deleteById(id) {
      const { data: res } = await this.$http.delete(`/ma/user/${id}`);
      if (res.code === 0) {
        this.$message.success(res.msg);
        this.getUserList();
      } else {
        this.$message.error(res.msg);
      }
    },
    async resetDeviceId(id) {
      const { data: res } = await this.$http.get(`/ma/user/resetDevice/${id}`);
      if (res.code === 0) {
        this.$message.success(res.msg);
        this.getUserList();
        this.addDialogVisible = false;
      } else {
        this.$message.error(res.msg);
      }
    },
    async resetPassword(id) {
      const { data: res } = await this.$http.get(
        `/ma/user/resetPassword/${id}`
      );
      if (res.code === 0) {
        this.$message.success(res.msg);
        this.getUserList();
        this.addDialogVisible = false;
      } else {
        this.$message.error(res.msg);
      }
    },
    initEditUserRole(data) {
      this.editForm.roleId = data.roleId;
      console.log(data);
    },
    initEditUserPosition(data) {
      this.editForm.roleId = data.roleId;
      this.editForm.positionId = data.positionId;
      console.log("initEditUserPosition" + data);
    },
    initAddUserPosition(data) {
      this.addForm.positionId = data.positionId;
    },
    initEditUserInfo(data) {
      this.editForm.dealerCode = data;
      console.log(data);
    },
    async showEditDialog(id) {
      const { data: res } = await this.$http.get(`/ma/user/${id}`);
      this.editForm.id = res.data.id;
      this.editForm.userName = res.data.userName;
      this.editForm.nameCn = res.data.nameCn;
      this.editForm.email = res.data.email;
      this.editForm.dealerCode = res.data.dealerCode;
      this.editForm.positionId = res.data.positionId;
      this.editForm.roleId = res.data.roleId;

      console.log(this.editForm);
      this.editDialogVisible = true;
    },
    addUser() {
      this.$refs.addFormRef.validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.post(
            "/ma/user/addUser",
            this.addForm
          );
          if (res.code === 0) {
            this.$message.success(res.msg);
            this.getUserList();
            this.addDialogVisible = false;
          } else {
            this.$message.error(res.msg);
          }
        }
      });
    },
    closeAddDialog() {
      this.$refs.addFormRef.resetFields();
      this.$refs.dealerAddRef.clear();
      this.$refs.roleAddRef.clear();
      this.addForm.roleId = null;
      this.addForm.positionId = null;
    },
    initAddUserInfo(data) {
      this.addForm.dealerCode = data;
    },
    initAddUserRole(data) {
      this.addForm.roleId = data.roleId;
      this.addForm.positionId = data.positionId;
    },
    async updateStatus(user) {
      const { data: res } = await this.$http.put(
        `/ma/user/${user.id}/state/${user.state === "PASS"}`
      );
      if (res.code === 0) {
        if (user.state === "PASS") {
          user.state = "REFUSE";
        } else {
          user.state = "PASS";
        }
        return this.$message.success(res.msg);
      } else {
        user.state = !user.state;
        return this.$message.error(res.msg);
      }
    },
    // pageSize改变
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.loading = true;
      this.getUserList();
    },
    // pageNum 改变
    handleCurrentChange(newPage) {
      this.queryInfo.pageNum = newPage;
      this.getUserList();
    },
    initQueryInfo(data) {
      this.queryInfo.query.roleId = data.roleId;
      this.queryInfo.query.positionId = data.positionId;
    },
    initQueryInfoForDealer(data) {
      this.queryInfo.query.dealerCode = data;
    },
    async getUserList() {
      this.queryInfo.query.state = this.queryInfo.query.state
        ? this.queryInfo.query.state
        : null;
      const { data: res } = await this.$http.post(
        "/ma/user/list",
        this.queryInfo
      );
      this.loading = false;
      this.userList = res.data.content;
      this.total = res.data.totalElements;
    },
    searchList() {
      this.queryInfo.pageNum = 1;
      this.loading = true;
      this.getUserList();
    },
    clearCondition() {
      this.queryInfo.query.userName = null;
      this.queryInfo.query.roleId = null;
      this.queryInfo.query.positionId = null;
      this.queryInfo.query.dealerCode = null;
      this.queryInfo.query.state = null;
      this.$refs.roleChild.clear();
      this.$refs.dealerChild.clear();
      this.getUserList();
    },
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getUserList();
    this.getuploadtime();
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {}, // 生命周期 - 挂载之前
  beforeUpdate() {}, // 生命周期 - 更新之前
  updated() {}, // 生命周期 - 更新之后
  beforeDestroy() {}, // 生命周期 - 销毁之前
  destroyed() {}, // 生命周期 - 销毁完成
  activated() {}, // 如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang="less" scoped>
.text {
  font-size: 14px;
}
.item {
  margin-bottom: 18px;
}
.el-table {
  margin-top: 20px;
}
.uploadtime {
  font-size: 14px;
  color: #606266;
  display: flex;
  align-items: center;
  height: 40px;
  justify-content: flex-end;
}
</style>
